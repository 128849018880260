import { InternalRouteName } from '~/constants/internal-route-name'

interface ReservationParams {
    category?: string
    depTime?: string
    retTime?: string
    depAgency?: string
    vehicle?: string
}

function getParams() {
    const route = useRoute()
    const { category, depTime, retTime, depAgency, vehicle } = route.params as Record<string, string | undefined>

    return { category, depTime, retTime, depAgency, vehicle }
}

export function useReservationLink(params?: ReservationParams) {
    const category = computed(() => params?.category || getParams().category)
    const depTime = computed(() => params?.depTime || getParams().depTime)
    const retTime = computed(() => params?.retTime || getParams().retTime)
    const depAgency = computed(() => params?.depAgency || getParams().depAgency)
    const vehicle = computed(() => params?.vehicle || getParams().vehicle)

    const allParams = computed(() => {
        if (!category.value || !depTime.value || !retTime.value || !depAgency.value || !vehicle.value) {
            // console.log('Not all reservation params are filled')
            return
        }

        return {
            category: category.value,
            depTime: depTime.value,
            retTime: retTime.value,
            depAgency: depAgency.value,
            vehicle: vehicle.value,
        }
    })

    const listingRoute = computed(() => {
        if (!category.value || !depTime.value || !retTime.value || !depAgency.value) {
            // console.log('missing params for access listing route')
            return
        }

        return {
            name: InternalRouteName.RESERVATION_LISTING,
            params: {
                category: category.value,
                depTime: depTime.value,
                retTime: retTime.value,
                depAgency: depAgency.value,
            },
        }
    })

    const requestRoute = computed(() => {
        if (allParams.value) return { name: InternalRouteName.RESERVATION_REQUEST, params: allParams.value }
    })

    const optionsRoute = computed(() => {
        if (allParams.value) return { name: InternalRouteName.RESERVATION_OPTIONS, params: allParams.value }
    })

    const accessoriesRoute = computed(() => {
        if (allParams.value) return { name: InternalRouteName.RESERVATION_ACCESSORIES, params: allParams.value }
    })

    const summaryRoute = computed(() => {
        if (allParams.value) return { name: InternalRouteName.RESERVATION_SUMMARY, params: allParams.value }
    })

    return {
        listingRoute,
        requestRoute,
        optionsRoute,
        accessoriesRoute,
        summaryRoute,
    }
}
